
import {computed, defineComponent, onMounted, onUnmounted, ref} from "vue";
import {StepperComponent} from "@/assets/ts/components";
import * as Yup from "yup";
import UserService from "@/core/services/UserService";
import {ErrorMessage, Field, Form} from "vee-validate";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import AddressSelect from "@/components/base/select/AddressSelect.vue";
import {ModelAddress} from "@/core/entity/IAddress";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {COMPANY_SIZE, INDUSTRY} from "@/core/config/Constant";

export default defineComponent({
  name: "RegisterCompany",
  components: {BaseSelect, AddressSelect, FormErrorMessage, Field, Form, ErrorMessage},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    const stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    onMounted(() => {
      stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );
    });

    const error = ref({error: false, message: ''})
    const state = ref<any>({
      companyName: '', spaceName: 'Default Space', groupName: 'General', dashboardName: 'First Dashboard',
      spacePrivacy: 'PUBLIC',
      libraryName: 'First Library',
      spaceConfig: {work: true, metric: true, task: true, timer: false, documentLibrary: true},
      companyConfig: {
        website: '',
        phone: '',
        address: new ModelAddress(),
        size: 'SIZE_1_10',
        industry: 'I_1',
      }
    });
    const registered = ref(false)
    const validate = Yup.object().shape({
      companyName: Yup.string().required().label("Company Name"),
      website: Yup.string().required().label("Website"),
      phone: Yup.string().required().label("Phone"),
      size: Yup.string().required().label("Size"),
      industry: Yup.string().required().label("Industry"),
      spaceName: Yup.string().required().label("Workspace Name"),
      libraryName: Yup.string().required().label("First Library"),
      dashboardName: Yup.string().required().label("First Dashboard"),
    });
    const totalSteps = computed(() => {
      if (!stepperObj.value) {
        return;
      }

      return stepperObj.value.totatStepsNumber;
    });

    const previousStep = () => {
      if (!stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      stepperObj.value.goPrev();
    };
    const handleStep = () => {

      currentStepIndex.value++;

      if (!stepperObj.value) {
        return;
      }

      stepperObj.value.goNext();
    }

    return {
      stepperObj,
      totalSteps,
      previousStep,
      handleStep,
      registered,
      validate,
      COMPANY_SIZE,
      INDUSTRY,
      createAccountRef,
      currentStepIndex,
      state,
      error,
    }
  },
  methods: {
    async nextStep() {
      const form = this.$refs.registerForm as typeof Form
      form.validate();
      if (this.currentStepIndex === 0) {
        const companyName = await form.validateField("companyName")
        const website = await form.validateField("website")
        const phone = await form.validateField("phone")
        const size = await form.validateField("size")
        const industry = await form.validateField("industry")
        if (!companyName.valid || !website.valid || !phone.valid || !size.valid || !industry.valid) {
          return
        }
      }
      if (this.currentStepIndex === 1) {
        const spaceName = await form.validateField("spaceName")
        if (!spaceName.valid) {
          console.log(spaceName.valid)
          return
        }
      }
      if (this.currentStepIndex === 2) {
        const lib = await form.validateField("libraryName")
        const dashboard = await form.validateField("dashboardName")
        if (!lib.valid || !dashboard.valid) {
          return
        }
      }

      this.currentStepIndex++;
      if (!this.stepperObj) {
        return;
      }

      this.stepperObj.goNext();
    },
    async formSubmit() {
      const form = this.$refs.registerForm as typeof Form
      if (this.currentStepIndex === 2) {
        const lib = await form.validateField("libraryName")
        const dashboard = await form.validateField("dashboardName")
        if (!lib.valid || !dashboard.valid) {
          return
        }
      }
      const submitButton = this.$refs.submitButton as typeof HTMLButtonElement.prototype;
      submitButton.disabled = true
      submitButton?.setAttribute("data-kt-indicator", "on");
      UserService.registerCompany(this.state).then(status => {
        if (status.status) {
          window.location.href = "/company/" + status.companyId + "/users"
        }
      }).catch(eror => {
        const finalError = {};
        eror.data.errors.forEach(item => {
          finalError[item.field] = [item.message]
        })
        const form = this.$refs.registerForm as typeof HTMLFormElement.prototype
        form.setErrors(finalError);
      }).finally(() => {
        submitButton.disabled = false;
        submitButton?.removeAttribute("data-kt-indicator");
      })
    }
  }
})
